import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { EquipmentTypeComponentService } from 'services/equipment-type-component-service';
import { EquipmentTypeService } from 'services/equipment-type-service';
import { ErrorService } from 'services/error-service';
import { NettingTypeService } from 'services/netting-type-service';
import { PriceCalculationTypeService } from 'services/price-calculation-type-service';
import { ProductService } from 'services/product-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class EquipmentTypeForm {
  @bindable() private equipmenttype: Models.EquipmentType;

  private products: Array<Models.Product>;
  private equipmentTypeComponents: Array<Models.EquipmentTypeComponent>;
  private newEquipmentTypeComponent: any = { PriceCalculationTypeId: 1, newComponent: true, _unit: 'general.qty' };
  private priceCalculationTypes: Array<Models.PriceCalculationType>;

  private equipmentTypeComponentNewFormVisible: boolean = false;
  private equipmentTypeComponentEditFormVisible: any = null;

  private navisionProducts: Promise<Array<Models.Product>>;
  private deltaProducts: Promise<Array<Models.Product>>;

  protected disableNavisionInputNew = false;
  protected disableDeltaInputNew = false;
  protected disableNavisionInputEdit = false;
  protected disableDeltaInputEdit = false;

  constructor(
    private errorService: ErrorService,
    private productService: ProductService,
    private equipmentTypeComponentService: EquipmentTypeComponentService,
    private priceCalculationTypeService: PriceCalculationTypeService,
    private toastService: ToastService,
    private nettingTypeService: NettingTypeService,
    private equipmentTypeService: EquipmentTypeService,
    private eventAggregator: EventAggregator,
    private router: Router
  ) {}

  public bind() {
    void this.getAllProducts();
    void this.getEquipmentTypeComponents();
  }

  private async getAllProducts() {
    try {
      this.products = await this.productService.getAllCached();
      this.navisionProducts = Promise.resolve(this.products.filter((x) => x.ProductSource === 1));
      this.deltaProducts = Promise.resolve(this.products.filter((x) => x.ProductSource === 2));
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private getEquipmentTypeComponents() {
    if (this.equipmenttype.Id) {
      this.equipmentTypeComponentService
        .getAll('?$filter=EquipmentTypeId eq ' + this.equipmenttype.Id)
        .then((res) => {
          res.forEach((c) => this.equipmentTypeComponentService.setComponentUnit(c));

          this.equipmentTypeComponents = res;
        })
        .catch((err) => this.errorService.handleError(err));
    }
  }

  protected setNavisionProductId(event, equipmentTypeComponent) {
    equipmentTypeComponent.NavisionProductId = event.detail.value;
    this.setProductName(event.detail.value, equipmentTypeComponent);
    this.validateFields(equipmentTypeComponent);
  }

  protected setDeltaProductId(event, equipmentTypeComponent) {
    equipmentTypeComponent.DeltaProductId = event.detail.value;
    this.setProductName(event.detail.value, equipmentTypeComponent);
    this.validateFields(equipmentTypeComponent);
  }

  private setProductName(productId: number, equipmentTypeComponent) {
    if (
      !equipmentTypeComponent.Name ||
      equipmentTypeComponent.Name === '' ||
      equipmentTypeComponent.newComponent === true
    ) {
      const product = this.products.find((x) => x.Id === productId);
      if (product) {
        equipmentTypeComponent.Name = product.Name;
        equipmentTypeComponent.NameEn = product.Name;
        equipmentTypeComponent.NameEs = product.Name;
      }
    }
  }

  private createEquipmentTypeComponent() {
    this.newEquipmentTypeComponent.EquipmentTypeId = this.equipmenttype.Id;

    delete this.newEquipmentTypeComponent.newComponent;

    if (parseInt(this.newEquipmentTypeComponent.PriceCalculationTypeId, 10) === 3) {
      this.newEquipmentTypeComponent.DeltaProductId = null;
      this.newEquipmentTypeComponent.NavisionProductId = null;
    }

    delete this.newEquipmentTypeComponent['_unit'];

    this.equipmentTypeComponentService
      .post(this.newEquipmentTypeComponent)
      .then((res) => {
        this.toastService.showSuccess('equipmentTypeComponent.successfullyCreatedNew');
        this.equipmentTypeComponentNewFormVisible = false;
        this.newEquipmentTypeComponent = { PriceCalculationTypeId: 1, newComponent: true, _unit: 'general.qty' };
        this.getEquipmentTypeComponents();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private setPriceCalculationTypeId(event, component: Models.EquipmentTypeComponent) {
    component.PriceCalculationTypeId = event.detail.value;

    this.equipmentTypeComponentService.setComponentUnit(component);

    this.validateFields(component);
  }

  private updateEquipmentTypeComponent(equipmentTypeComponent) {
    delete equipmentTypeComponent._unit;

    this.equipmentTypeComponentService
      .put(equipmentTypeComponent, equipmentTypeComponent.Id)
      .then((res) => {
        this.toastService.showSuccess('equipmentTypeComponent.successfullyUpdated');
        this.equipmentTypeComponentEditFormVisible = null;
        this.getEquipmentTypeComponents();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteEquipmentTypeComponent(equipmentTypeComponent) {
    this.equipmentTypeComponentService
      .delete(equipmentTypeComponent.Id)
      .then((res) => {
        this.toastService.showSuccess('equipmentTypeComponent.successfullyDeleted');
        this.getEquipmentTypeComponents();
        if (this.equipmentTypeComponentEditFormVisible === equipmentTypeComponent.Id) {
          this.equipmentTypeComponentEditFormVisible = null;
        }
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private validateFields(component) {
    this.validateNavisionInput(component);
    this.validateDeltaInput(component);
  }

  private validateNavisionInput({ PriceCalculationTypeId, DeltaProductId, newComponent }) {
    let res: boolean;
    if (!PriceCalculationTypeId) {
      res = false;
    } else {
      if (parseInt(PriceCalculationTypeId, 10) === 3) {
        res = true;
      }
    }

    if (newComponent) {
      this.disableNavisionInputNew = res;
    } else {
      this.disableNavisionInputEdit = res;
    }
  }

  private validateDeltaInput({ PriceCalculationTypeId, NavisionProductId, newComponent }) {
    let res: boolean;
    if (!PriceCalculationTypeId) {
      res = false;
    } else {
      if (parseInt(PriceCalculationTypeId, 10) === 3) {
        res = true;
      }
    }

    if (newComponent) {
      this.disableDeltaInputNew = res;
    } else {
      this.disableDeltaInputEdit = res;
    }
  }

  private cancelFormEdit() {
    this.disableNavisionInputEdit = false;
    this.disableDeltaInputEdit = false;
    this.equipmentTypeComponentEditFormVisible = null;
  }

  private editForm(component) {
    this.equipmentTypeComponentEditFormVisible = component.Id;
    this.validateFields(component);

    this.equipmentTypeComponentService.setComponentUnit(component);
  }

  private saveEquipmentType() {
    if (this.equipmenttype.Id) {
      this.equipmentTypeService
        .put(this.equipmenttype, this.equipmenttype.Id)
        .then((res) => {
          this.eventAggregator.publish('equipmentTypeListReset', 1);
          this.toastService.showSuccess('equipmenttype.updated');
          this.router.navigateToRoute('equipment-type-list');
        })
        .catch((err) => this.errorService.handleError(err));
    } else {
      this.equipmentTypeService
        .post(this.equipmenttype)
        .then((res) => {
          this.eventAggregator.publish('equipmentTypeListReset', 1);
          this.toastService.showSuccess('equipmenttype.created');
          this.router.navigateToRoute('equipment-type-detail', { Id: res.Id });
        })
        .catch((err) => this.errorService.handleError(err));
    }
  }

  private gotoParentView() {
    this.eventAggregator.publish('equipmentTypeListReset', 1);
    this.router.navigateToRoute('equipment-type-list');
  }
}
