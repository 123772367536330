import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { Models } from "models/core";
import { autoinject } from "aurelia-framework";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator";
import { EquipmentTypeService } from "services/equipment-type-service";

@autoinject
export class EquipmentTypeNew {
  private equipmenttype: Models.EquipmentType = new Models.EquipmentType();

  constructor(
    private equipmentTypeService: EquipmentTypeService, 
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  createEquipmentType() {
    this.equipmentTypeService
      .post(this.equipmenttype)
      .then(res => {
        this.eventAggregator.publish("equipmentTypeListReset", 1);
        this.toastService.showSuccess("equipmenttype.created");
        this.router.navigateToRoute("equipment-type-detail", { Id: res.Id });
      })
      .catch(err => this.errorService.handleError(err));
  }
}
