import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, bindingMode, containerless, LogManager } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { EquipmentService } from 'services/equipment-service';
import { EquipmentTypeService } from 'services/equipment-type-service';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';

const logger: Logger = LogManager.getLogger("equipment-form-inline-new");

@containerless
@autoinject
export class EquipmentFormInlineNew {
  @bindable private netId: number;
  @bindable private centerBottom: boolean;
  
  private equipment: Models.Equipment = new Models.Equipment();
  
  constructor(
    private equipmentService: EquipmentService,
    private equipmentTypeService: EquipmentTypeService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService
  ) {}

  private attached() {
    
  }

  private async getEquipmentTypes() {
    let equipmentTypes = await this.equipmentTypeService.getAllCached();
    if (this.centerBottom != undefined) {
      equipmentTypes = equipmentTypes.filter(x => x.IsCenterBottomEquipment == this.centerBottom);
      logger.debug('new equipmentTypes', this.centerBottom, equipmentTypes);
    }
    return equipmentTypes;
  }

  private createEquipment() {
    this.equipment.NetId = this.netId;
    this.equipment.IsCenterBottomEquipment = this.centerBottom != undefined ? this.centerBottom : false;

    this.equipmentService
      .post(this.equipment)
      .then((res) => {
        this.eventAggregator.publish('equipmentListReset', 1);
        this.eventAggregator.publish('equipmentFormNewClose', 1);
        this.toastService.showSuccess('equipment.created');
        this.equipment = new Models.Equipment();
      })
      .catch((err) => this.errorService.handleError(err));
  }
  
  private cancel() {
    this.eventAggregator.publish('equipmentFormNewClose', 1);
  }

}
