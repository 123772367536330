import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 
import { EquipmentTypeService } from "services/equipment-type-service";

@autoinject
export class equipmentTypeDetail {
  private equipmenttype: Models.EquipmentType;

  constructor(
    private equipmentTypeService: EquipmentTypeService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  activate(params) {
    this.equipmentTypeService
      .get(params.Id)
      .then(equipmentType => {
        this.equipmenttype = equipmentType;
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteEquipmentType() {    
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.equipmentTypeService
          .delete(this.equipmenttype.Id)
          .then(res => {
            this.eventAggregator.publish("equipmentTypeListReset", 1);
            this.toastService.showSuccess("equipmenttype.deleted");
            this.router.navigateToRoute("equipment-type-list");
          })
          .catch(err => this.errorService.handleError(err));
        }
      );    
  }

}
