import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, bindingMode, containerless, LogManager } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { EquipmentService } from 'services/equipment-service';
import { EquipmentTypeService } from 'services/equipment-type-service';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';

const logger: Logger = LogManager.getLogger("equipment-form-inline-edit");

@containerless
@autoinject
export class EquipmentFormInlineEdit {
  @bindable private equipment: Models.Equipment = new Models.Equipment();
  @bindable private centerBottom: boolean;

  constructor(
    private equipmentService: EquipmentService,
    private equipmentTypeService: EquipmentTypeService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService
  ) {}

  private attached() {    
  }

  private async getEquipmentTypes() {
    let equipmentTypes = await this.equipmentTypeService.getAllCached();
    if (this.centerBottom != undefined) {
      equipmentTypes = equipmentTypes.filter(x => x.IsCenterBottomEquipment == this.centerBottom);
      logger.debug('edit equipmentTypes', this.centerBottom, equipmentTypes);
    }
    return equipmentTypes;
  }

  private updateEquipment() {
    this.equipment.EquipmentType = null;
    this.equipmentService
      .put(this.equipment, this.equipment.Id)
      .then((res) => {
        this.eventAggregator.publish('equipmentListReset', 1);
        this.eventAggregator.publish('equipmentFormEditClose', 1);
        this.toastService.showSuccess('equipment.updated');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteEquipment() {
    this.equipmentService
      .delete(this.equipment.Id)
      .then((res) => {
        this.eventAggregator.publish('equipmentFormEditClose', 1);
        this.eventAggregator.publish('equipmentListReset', 1);
        this.toastService.showSuccess('equipment.deleted');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private cancelEdit() {
    this.eventAggregator.publish('equipmentFormEditClose', 1);
    this.eventAggregator.publish('equipmentListReset', 1);
  }

}
